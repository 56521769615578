/**
 * @file Implementation of the top link block
 */

// -------------------------- BEGIN MODULE VARIABLES --------------------------
let topLink;
// --------------------------- END MODULE VARIABLES ---------------------------

// -------------------------- BEGIN UTILITY FUNCTIONS -------------------------
const scrollTo = function(target, duration, cb) {
    const now = 'now' in window.performance
        ? () => performance.now()
        : () => new Date().getTime();

    const startOffset = window.pageYOffset;
    const startTime = now();

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const windowHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.getElementsByTagName('body')[0].clientHeight;
    const targetOffset = 
        Math.round((documentHeight - target.offsetTop < windowHeight)
            ? documentHeight - windowHeight
            : target.offsetTop);

    if (!('requestAnimationFrame' in window)) {
        window.scroll(0, targetOffset);
        
        if (cb) {
            cb();
        }

        return;
    }

    function scroll() {
        const t = Math.min(1, ((now() - startTime) / duration));
        window.scroll(
            0,
            Math.ceil((t * (targetOffset - startOffset)) + startOffset)
        );
      
        if (t >= 1) {
            if (cb) {
                cb();
            }
            return;
        }

        requestAnimationFrame(scroll);
    }

    scroll();
};
// --------------------------- END UTILITY FUNCTIONS --------------------------

// ---------------------------- BEGIN PUBLIC METHODS --------------------------
/**
 * Initialize the top link module.
 * @return true;
 */
export const initModule = function() {
    topLink = document.querySelector('.top-link');
    if (topLink == null) {
        return false;
    }

    topLink.addEventListener('click', function onTopLinkClick(event) {
        event.preventDefault();

        const targetId = this.getAttribute('href');
        if (!/^#[a-zA-Z][\w:.-]*$/.test(targetId)) {
            return;
        }

        const targetNode = document.querySelector(targetId);
        if (targetNode == null) {
            return;
        }

        scrollTo(targetNode, 600, () => {
            targetNode.focus();
        });
    });

    return true;
};

export const handleScroll = function() {
    if (window.pageYOffset >= 1000) {
        topLink.classList.add('top-link_visible');
    } else {
        topLink.classList.remove('top-link_visible');
    }
};
// ---------------------------- END PUBLIC METHODS ----------------------------