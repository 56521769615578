import focusTrap from 'focus-trap';
import svg4everybody from 'svg4everybody';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import Promise from 'promise-polyfill';
import {
    Swiper,
    Navigation,
    Pagination,
    Autoplay,
    EffectFade,
} from 'swiper/dist/js/swiper.esm.js';

// Install swipermodules
Swiper.use([ Autoplay, EffectFade, Navigation, Pagination ]);

import 'nodelist-foreach-polyfill';

import * as Page from '../blocks/common/page/page';

// Expose libraries to global Window object
window.focusTrap = focusTrap;
window.PhotoSwipe = PhotoSwipe;
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;
window.Promise = Promise;
window.Swiper = Swiper;

// Manually initialize third-party libraries
svg4everybody();

// The page block is responsible for the initialization of all other blocks
Page.initModule();
