/**
 * @file Implementation of the product carousel block
 */

/* global Swiper */

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
export const initBlock = function(container) {
    const swiper = container.querySelector('.swiper-container');
    const prevArrow = container.querySelector('.product-carousel__arrow_prev');
    const nextArrow = container.querySelector('.product-carousel__arrow_next');
    const pagination = container.querySelector('.product-carousel__pagination');

    new Swiper(swiper, {
        navigation: {
            prevEl: prevArrow,
            nextEl: nextArrow,
        },

        pagination: {
            el: pagination,
            clickable: true,
            bulletClass: 'product-carousel__bullet',
            bulletActiveClass: 'product-carousel__bullet_active',
            modifierClass: 'product-carousel__pagination_',
            clickableClass: 'product-carousel__pagination_clickable',
        },

        slidesPerView: 1,
        spaceBetween: 30,

        breakpointsInverse: true,
        breakpoints: {
            580: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
        },
    });
};

/**
 * Initialize the product carousel module.
 * @return true;
 */
export const initModule = function() {
    document.querySelectorAll('.product-carousel').forEach(initBlock);
    return true;
};
// ---------------------------- END PUBLIC METHODS ----------------------------
