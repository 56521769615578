/**
 * @file Implementation of the slider block
 */

/* global Swiper */

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
export const initBlock = function(root) {
    const container = root.querySelector('.post-gallery__container');
    const prevArrow = root.querySelector('.post-gallery__arrow_prev');
    const nextArrow = root.querySelector('.post-gallery__arrow_next');

    new Swiper(container, {
        navigation: {
            prevEl: prevArrow,
            nextEl: nextArrow,
        },
        loop: true,
    });
};

/**
 * Initialize the slider module.
 * @return true;
 */
export const initModule = function() {
    document.querySelectorAll('.post-gallery').forEach(initBlock);
    return true;
};
// ---------------------------- END PUBLIC METHODS ----------------------------
