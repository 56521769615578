/**
 * @file Implementation of the language menu block
 */

// -------------------------- BEGIN MODULE VARIABLES --------------------------
const KEY_ESC   = 27;
const KEY_SPACE = 32;

let langMenu, toggle, drop;
let isExpanded = false;
// --------------------------- END MODULE VARIABLES ---------------------------

// ----------------------------- BEGIN DOM METHODS ----------------------------
/**
 * Toggle visibility of the language menu
 * @param {boolean} open true to show the menu, false to hide
 */
function toggleMenu(open) {
    isExpanded = open;

    if (open) {
        drop.classList.add('lang-menu__drop_visible', open);
    
        setTimeout(function() {
            document.addEventListener('click', onOutsideClick, false);
            document.addEventListener('focusin', onOutsideClick, false);
            langMenu.addEventListener('keydown', onKeydown, false);
        }, 0);
    } else {
        drop.classList.remove('lang-menu__drop_visible', open);

        document.removeEventListener('click', onOutsideClick, false);
        document.removeEventListener('focusin', onOutsideClick, false);
        langMenu.removeEventListener('keydown', onKeydown, false);
    }

    toggle.setAttribute('aria-expanded', String(open));
}
// ------------------------------ END DOM METHODS -----------------------------

// --------------------------- BEGIN EVENT HANDLERS ---------------------------
function onOutsideClick(event) {
    const target = event.target;
    if ((target != document) && !langMenu.contains(target)) {
        toggleMenu(false);
    }
}

function onToggleClick() {
    toggleMenu(!isExpanded);
}

function onToggleKeydown(event) {
    const key = event.keyCode || event.which;
    if (key === KEY_SPACE) {
        event.preventDefault();
        toggleMenu(!isExpanded);
    }
}

function onToggleKeyup(event) {
    const key = event.keyCode || event.which;
    if (key === KEY_SPACE) {
        event.preventDefault();
    }
}

function onKeydown(event) {
    const key = event.keyCode || event.which;
    if (key === KEY_ESC) {
        toggleMenu(false);
        toggle.focus();
    }
}

function onMouseenter() {
    toggleMenu(true);
}

function onMouseleave() {
    toggleMenu(false);
}
// ---------------------------- END EVENT HANDLERS ----------------------------

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
/**
 * Initialize the language menu block.
 * @return true
 */
export function initBlock() {
    langMenu = document.querySelector('.lang-menu');
    toggle = langMenu.querySelector('.lang-menu__toggle');
    drop = langMenu.querySelector('.lang-menu__drop');

    toggle.addEventListener('click', onToggleClick, false);
    toggle.addEventListener('keydown', onToggleKeydown, false);
    toggle.addEventListener('keyup', onToggleKeyup, false);

    langMenu.addEventListener('mouseenter', onMouseenter, false);
    langMenu.addEventListener('mouseleave', onMouseleave, false);

    return true;
}
// ---------------------------- END PUBLIC METHODS ----------------------------
