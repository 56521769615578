/**
 * @file Implementation of the header block
 * @author Andrey Glotov
 */

// -------------------------- BEGIN MODULE VARIABLES --------------------------
const STICKY_HEADER_OFFSET  = 150;
const VISIBLE_HEADER_OFFSET = 500;

const HeaderStates = { NORMAL: 0, STICKY: 1, VISIBLE: 2 };
let headerState = HeaderStates.NORMAL;

let header, headerBottom, nav, logo;
let timerId;
// --------------------------- END MODULE VARIABLES ---------------------------

// ---------------------------- BEGIN DOM METHODS -----------------------------
/**
 * Add or remove header classes based on the current scroll offset to create an
 * animated sticky header effect.
 */
function updateHeaderStyles() {
    const newState = (window.pageYOffset < STICKY_HEADER_OFFSET)
        ? HeaderStates.NORMAL
        : (window.pageYOffset < VISIBLE_HEADER_OFFSET)
            ? HeaderStates.STICKY
            : HeaderStates.VISIBLE;

    if (newState !== headerState) {
        clearTimeout(timerId);

        if (newState === HeaderStates.NORMAL) {
            headerBottom.classList.remove('header__bottom_scroll');
            headerBottom.classList.remove('header__bottom_hidden');
            header.style.paddingBottom = 0;
            nav.classList.remove('nav_scroll');
            logo.classList.remove('logo_small');
        } else if (newState === HeaderStates.STICKY) {
            headerBottom.classList.add('header__bottom_scroll');
            headerBottom.classList.add('header__bottom_hidden');
            header.style.paddingBottom = headerBottom.offsetHeight + 'px';
            nav.classList.add('nav_scroll');
            logo.classList.add('logo_small');
        } else {
            if (headerState === HeaderStates.NORMAL) {
                headerBottom.classList.add('header__bottom_scroll');
                headerBottom.classList.add('header__bottom_hidden');
                header.style.paddingBottom = headerBottom.offsetHeight + 'px';
                nav.classList.add('nav_scroll');
                logo.classList.add('logo_small');

                timerId = setTimeout(function() {
                    // Make sure that animation is played
                    headerBottom.classList.remove('header__bottom_hidden');
                }, 0);
            } else {
                headerBottom.classList.remove('header__bottom_hidden');
            }
        }

        headerState = newState;
    }
}
// ----------------------------- END DOM METHODS ------------------------------

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
/**
 * Initialize the header block.
 * @return true
 */
export function initBlock() {
    header = document.querySelector('.header');
    headerBottom = header.querySelector('.header__bottom');
    nav = headerBottom.querySelector('.header__nav');
    logo = headerBottom.querySelector('.header__logo');
    return true;
}

/**
 * Handle scroll event.
 */
export function handleScroll() {
    updateHeaderStyles();
}

/**
 * Handle resize event.
 */
export function handleResize() {
    if (headerState !== HeaderStates.NORMAL) {
        header.style.paddingBottom = headerBottom.offsetHeight + 'px';
    }
}
