/**
 * @file Implementation of the map block
 */

// -------------------------- BEGIN MODULE VARIABLES --------------------------
const urlCache = {};
// --------------------------- END MODULE VARIABLES ---------------------------

// -------------------------- BEGIN UTILITY FUNCTIONS -------------------------
function load(url) {
    return new Promise(function(resolve, reject) {
        if (urlCache.hasOwnProperty(url)) {
            return resolve(url);
        }

        const element = document.createElement('script');
        
        element.onload = function() {
            urlCache[url] = true;
            resolve(url);
        };
        
        element.onerror = function() {
            reject(url);
        };

        element.src = url;
        element.async = true;
        
        document.body.appendChild(element);
    });
}
// --------------------------- END UTILITY FUNCTIONS --------------------------

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
/**
 * Initialize the block.
 * @return true
 */
export const initBlock = function(container) {
    const { key, lang, center, zoom } = JSON.parse(container.dataset.map);

    load(`https://api-maps.yandex.ru/2.1/?apikey=${key}&lang=${lang}`)
        .then(() => {
            const ymaps = window.ymaps;

            ymaps.ready(() => {
                const map = new ymaps.Map(container, {
                    center,
                    zoom,
                    controls: [
                        'zoomControl',
                        'typeSelector', 
                        'fullscreenControl',
                    ],
                });
            
                map.geoObjects.add(new ymaps.Placemark(center, {}, {
                    preset: 'islands#icon',
                    iconColor: '#0095b6'
                }));
            
                map.behaviors.disable('scrollZoom');
            });
        });

    return true;
};

/**
 * Initialize the map module.
 * @return true
 */
export const initModule = function() {
    document.querySelectorAll('.map').forEach(initBlock);
    return true;
};
// ---------------------------- END PUBLIC METHODS ----------------------------
