/**
 * @file Implementation of the page block
 */

import * as Gallery from '../gallery/gallery';
import * as Header from '../header/header'; 
import * as LangMenu from '../lang-menu/lang-menu';
import * as Nav from '../nav/nav';
import * as Tabs from '../tabs/tabs';
import * as TopLink from '../top-link/top-link';
import * as PostGallery from '../../news/post-gallery/post-gallery';

import * as Slider from '../../index/slider/slider';
import * as ProductCarousel from '../../index/product-carousel/product-carousel';
import * as Map from '../../delivery/map/map';

// -------------------------- BEGIN MODULE VARIABLES --------------------------
const RESIZE_INTERVAL = 200;    // Resize debouncing interval
const SCROLL_INTERVAL = 200;    // Scroll throttling interval

let resizeTimer = null;
let scrollTimer = null;
let wasScrolled = false;
// --------------------------- END MODULE VARIABLES ---------------------------

// --------------------------- BEGIN EVENT HANDLERS ---------------------------
const onWindowScroll = function() {
    Header.handleScroll();
    TopLink.handleScroll();
};

const onWindowResize = function() {
    Header.handleResize();
    Nav.handleResize();
    Tabs.handleResize();
};
// ---------------------------- END EVENT HANDLERS ----------------------------

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
/**
 * Initialize the page module.
 * @return true
 */
export const initModule = function() {
    window.addEventListener('resize', function() {
        // Debounce the window resize event.
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(onWindowResize, RESIZE_INTERVAL);
    });

    window.addEventListener('scroll', function() {
        // Throttle the window scroll event.
        if (scrollTimer) {
            // ensure that we catch and execute that last invocation.
            wasScrolled = true;
            return;
        }

        onWindowScroll();

        scrollTimer = this.setTimeout(function() {
            scrollTimer = null;
            if (wasScrolled) {
                onWindowScroll();
                wasScrolled = false;
            }
        }, SCROLL_INTERVAL);
    });

    // Initialize blocks.
    Header.initBlock();
    LangMenu.initBlock();
    Nav.initBlock();
    
    Tabs.initModule();
    TopLink.initModule();

    Gallery.initModule();
    Slider.initModule();
    PostGallery.initModule();
    ProductCarousel.initModule();
    Map.initModule();

    // Process the initial window size and scroll position.
    onWindowResize();
    onWindowScroll();

    return true;
};
// ---------------------------- END PUBLIC METHODS ----------------------------
