/**
 * @file Implementation of the slider block
 */

/* global Swiper */

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
export const initBlock = function(container) {
    const prevArrow = container.querySelector('.slider__arrow_prev');
    const nextArrow = container.querySelector('.slider__arrow_next');
    const pagination = container.querySelector('.slider__pagination');

    new Swiper(container, {
        autoplay: {
            delay: 7000,
        },
        effect: 'fade',
        loop: true,

        navigation: {
            prevEl: prevArrow,
            nextEl: nextArrow,
        },

        pagination: {
            el: pagination,
            clickable: true,
            bulletClass: 'slider__bullet',
            bulletActiveClass: 'slider__bullet_active',
            modifierClass: 'slider__pagination_',
            clickableClass: 'slider__pagination_clickable',
        }
    });
};

/**
 * Initialize the slider module.
 * @return true;
 */
export const initModule = function() {
    document.querySelectorAll('.slider').forEach(initBlock);
    return true;
};
// ---------------------------- END PUBLIC METHODS ----------------------------
