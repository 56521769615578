/**
 * @file Implementation of the navigation block
 */

/* global focusTrap */

// -------------------------- BEGIN MODULE VARIABLES --------------------------
const DESKTOP_BREAKPOINT = 992; // Minimum desktop screen width

let isMobile = true;

const KEY_ESC   = 27;
const KEY_SPACE = 32;

const submenuList = [];
let nav, submenus, toggle, backdrop, drawer, close;
let trap;

const submenuProto = {
    _onOutsideClick(event) {
        const target = event.target;
        if ((target != document) && !this._container.contains(target)) {
            this.toggle(false);
        }
    },

    _onToggleClick(event) {
        if (isMobile) {
            event.preventDefault();
        }
        this.toggle(!this._isExpanded);
    },

    _onToggleKeydown(event) {
        const key = event.keyCode || event.which;
        if (key === KEY_SPACE) {
            event.preventDefault();
            this.toggle(!this._isExpanded);
        }
    },

    _onToggleKeyup(event) {
        const key = event.keyCode || event.which;
        if (key === KEY_SPACE) {
            event.preventDefault();
        }
    },

    _onKeydown(event) {
        const key = event.keyCode || event.which;
        if (key === KEY_ESC) {
            this.toggle(false);
            this._link.focus();
        }
    },

    _onMouseenter() {
        this.toggle(true);
    },
    
    _onMouseleave() {
        this.toggle(false);
    },

    toggle(open) {
        this._isExpanded = open;

        if (open) {
            this._drop.classList.add('nav__submenu_visible');

            setTimeout(() => {
                document.addEventListener('click', this._onOutsideClick);
                document.addEventListener('focusin', this._onOutsideClick);
                this._container.addEventListener('keydown', this._onKeydown);
            }, 0);
        } else {
            this._drop.classList.remove('nav__submenu_visible');

            document.removeEventListener('click', this._onOutsideClick);
            document.removeEventListener('focusin', this._onOutsideClick);
            this._container.removeEventListener('keydown', this._onKeydown);
        }

        this._link.setAttribute('aria-expanded', String(open));
        
        if (isMobile) {
            if (open) {
                this._drop.style.height = this._drop.scrollHeight + 'px';
            } else {
                this._drop.style.height = '';
            }
        }
    },

    accordionMode() {
        this._container.removeEventListener('mouseenter', this._onMouseenter);
        this._container.removeEventListener('mouseleave', this._onMouseleave);
    },

    dropdownMode() {
        this._container.addEventListener('mouseenter', this._onMouseenter);
        this._container.addEventListener('mouseleave', this._onMouseleave);
    },
};
// --------------------------- END MODULE VARIABLES ---------------------------

// ----------------------------- BEGIN DOM METHODS ----------------------------
function openNav() {
    toggle.setAttribute('aria-expanded', 'true');
    backdrop.classList.add('nav__backdrop_visible');
    drawer.classList.add('nav__drawer_visible');
}

function closeNav() {
    toggle.setAttribute('aria-expanded', 'false');
    backdrop.classList.remove('nav__backdrop_visible');
    drawer.classList.remove('nav__drawer_visible');
}
// ------------------------------ END DOM METHODS -----------------------------

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
/**
 * Initialize the navigation block.
 * @return true;
 */
export function initBlock() {
    nav = document.querySelector('.nav');
    submenus = nav.querySelectorAll('.nav__submenu');
    toggle = nav.querySelector('.nav__toggle');
    backdrop = nav.querySelector('.nav__backdrop');
    drawer = nav.querySelector('.nav__drawer');
    close = nav.querySelector('.nav__close');

    trap = focusTrap(drawer, {
        clickOutsideDeactivates : true,
        initialFocus            : drawer,
        onDeactivate            : closeNav,
    });

    toggle.addEventListener('click', function onNavToggle() {
        openNav();
        trap.activate();
    });
    
    close.addEventListener('click', function onNavClose() {
        trap.deactivate();
    });

    submenus.forEach((node) => {
        const item = node.parentNode;
        const link = item.querySelector('.nav__menu-link');

        const submenu = Object.create(submenuProto);

        submenu._container = item;
        submenu._link = link;
        submenu._drop = node;
        submenu._open = false;

        submenu._onOutsideClick = submenu._onOutsideClick.bind(submenu);
        submenu._onToggleClick = submenu._onToggleClick.bind(submenu);
        submenu._onToggleKeydown = submenu._onToggleKeydown.bind(submenu);
        submenu._onToggleKeyup = submenu._onToggleKeyup.bind(submenu);
        submenu._onKeydown = submenu._onKeydown.bind(submenu);
        submenu._onMouseenter = submenu._onMouseenter.bind(submenu);
        submenu._onMouseleave = submenu._onMouseleave.bind(submenu);

        link.addEventListener('click', submenu._onToggleClick);
        link.addEventListener('keydown', submenu._onToggleKeydown);
        link.addEventListener('keyup', submenu._onToggleKeyup);

        submenuList.push(submenu);
    });

    return true;
}

/**
 * Respond to window resize event.
 */
export function handleResize() {
    // Switch between accordion submenus on mobile and dropdown submenus on
    // desktop
    if (!isMobile && (window.innerWidth < DESKTOP_BREAKPOINT)) {
        submenuList.forEach((submenu) => {
            submenu.accordionMode();
        });

        isMobile = true;
    } else if (isMobile && (window.innerWidth >= DESKTOP_BREAKPOINT)) {
        submenuList.forEach((submenu) => {
            submenu.toggle(false);
            submenu.dropdownMode();
        });

        trap.deactivate();

        isMobile = false;
    }
}
// ---------------------------- END PUBLIC METHODS ----------------------------
