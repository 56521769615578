/**
 * @file Implementation of the gallery block
 * @author Andrey Glotov
 */

/* global PhotoSwipe, PhotoSwipeUI_Default */

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
/**
 * Initialize a single gallery block.
 */
export const initBlock = function(container, id) {
    container.setAttribute('data-pswp-uid', id);
    
    const thumbs = container.querySelectorAll('.gallery__item');
    const pswpElem = container.querySelector('.pswp');

    const items = [];
    thumbs.forEach((thumb) => {
        const [w, h] = thumb.getAttribute('data-size').split('x');
        const img = thumb.querySelector('.gallery__img');
        items.push({
            src: thumb.getAttribute('href'),
            w,
            h,
            title: img && img.getAttribute('alt'),
        });
    });

    thumbs.forEach((item, i) => {
        item.addEventListener('click', function(e) {
            e.preventDefault();

            const options = {
                galleryUID: id,

                getThumbBoundsFn: function(index) {
                    const rect = thumbs[index].getBoundingClientRect();
                    const pageYScroll = window.pageYOffset;
                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                },

                index: i,
            };

            // Pass data to PhotoSwipe and initialize it
            const gallery = new PhotoSwipe(
                pswpElem,
                PhotoSwipeUI_Default,
                items,
                options
            );
            gallery.init();
        });
    });
};

/**
 * Initialize the gallery module.
 * @return true
 */
export const initModule = function() {
    document.querySelectorAll('.gallery').forEach(initBlock);
};
// ---------------------------- END PUBLIC METHODS ----------------------------
