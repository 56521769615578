/**
 * @file Implementation of the tabs block
 * @author Andrey Glotov
 */

// -------------------------- BEGIN MODULE VARIABLES --------------------------
const TRANSITION_DURATION = 250;
const MEDIUM_BREAKPOINT = 768;

const KEY_END = 35;
const KEY_HOME = 36;
const KEY_LEFT = 37;
const KEY_UP = 38;
const KEY_RIGHT = 39;
const KEY_DOWN = 40;

const allTabs = [];
let verticalLayout = true;

const tabsProto = {
    _onKeydown(event) {
        const key = event.keyCode || event.which;

        if (this._keyActions[key]) {
            event.preventDefault();

            this._keyActions[key].call(this);
        }
    },

    firstTab() {
        this.selectTab(0);
    },

    lastTab() {
        this.selectTab(this._links.length - 1);
    },

    prevTab() {
        let nextIndex = this._activeIndex - 1;
        if (nextIndex < 0) {
            nextIndex = this._links.length - 1;
        }

        this.selectTab(nextIndex);
    },

    nextTab() {
        let nextIndex = this._activeIndex + 1;
        if (nextIndex >= this._links.length) {
            nextIndex = 0;
        }

        this.selectTab(nextIndex);
    },

    selectTab(nextIndex) {
        if (this._activeIndex === nextIndex) {
            return;
        }

        const prevLink = this._links[this._activeIndex];
        const prevPanel = this._panels[this._activeIndex];
        const nextLink = this._links[nextIndex];
        const nextPanel = this._panels[nextIndex];

        prevLink.classList.remove('tabs__link_active');
        prevLink.setAttribute('aria-selected', 'false');
        prevLink.setAttribute('tabindex', '-1');

        nextLink.classList.add('tabs__link_active');
        nextLink.setAttribute('aria-selected', 'true');
        nextLink.setAttribute('tabindex', '0');

        prevPanel.classList.add('tabs__panel_fade');
        nextPanel.classList.add('tabs__panel_fade');

        setTimeout(function() {
            prevPanel.classList.remove(
                'tabs__panel_fade',
                'tabs__panel_active'
            );

            nextPanel.classList.remove('tabs__panel_fade');
            nextPanel.classList.add('tabs__panel_active');
        }, TRANSITION_DURATION);

        this._activeIndex = nextIndex;
    },

    setOrientation(orientation) {
        this._keyActions = (orientation === 'vertical')
            ? verticalKeyActions
            : horizontalKeyActions;

        this._list.setAttribute('aria-orientation', orientation);
    },
};

const verticalKeyActions = {
    [KEY_END]: tabsProto.lastTab,
    [KEY_HOME]: tabsProto.firstTab,
    [KEY_UP]: tabsProto.prevTab,
    [KEY_DOWN]: tabsProto.nextTab,
};

const horizontalKeyActions = {
    [KEY_END]: tabsProto.lastTab,
    [KEY_HOME]: tabsProto.firstTab,
    [KEY_LEFT]: tabsProto.prevTab,
    [KEY_RIGHT]: tabsProto.nextTab,
};
// --------------------------- END MODULE VARIABLES ---------------------------

// --------------------------- BEGIN PUBLIC METHODS ---------------------------
export const initBlock = function(container) {
    const tabs = Object.create(tabsProto);

    tabs._list = container.querySelector('.tabs__list');
    tabs._links = container.querySelectorAll('.tabs__link');
    tabs._panels = container.querySelectorAll('.tabs__panel');

    tabs._activeIndex = 0;

    tabs._list.addEventListener('keydown', tabs._onKeydown.bind(tabs));
    tabs._links.forEach((link, i) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            tabs.selectTab(i);
        });
    });

    tabs.setOrientation('vertical');

    allTabs.push(tabs);

    return true;
};

/**
 * Initialize the tabs module.
 * @return true
 */
export const initModule = function() {
    document.querySelectorAll('.tabs').forEach(initBlock);
};

/**
 * Respond to window resize event.
 */
export const handleResize = function() {   
    // Switch tabs orientation to vertical on mobile and to horizontal on
    // larger screens.
    if (!verticalLayout && (window.innerWidth < MEDIUM_BREAKPOINT)) {
        verticalLayout = true;

        allTabs.forEach((tab) => {
            tab.setOrientation('vertical');
        });
    } else if (verticalLayout && (window.innerWidth >= MEDIUM_BREAKPOINT)) {
        verticalLayout = false;

        allTabs.forEach((tab) => {
            tab.setOrientation('horizontal');
        });
    }
};
// ---------------------------- END PUBLIC METHODS ----------------------------